import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import spinner from "../common/spinner.gif";
import logo from "../common/logo.png";
import classNames from "classnames";
import { getLeads, uploadLeads } from "../../actions/leadActions";
import { submitPayment, fetchSubscriptions } from "../../actions/authActions";
import { addToInventory, checkFeed } from "../../actions/inventoryActions";
import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendFeedback } from "../../actions/leadActions";
import PurchaseModal from "../settings/PurchaseModal";

import {
  Grid,
  Paper,
  Button,
  Typography,
  Checkbox,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
} from "@material-ui/core";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import StripeCheckout from "react-stripe-checkout";
import FormDialog from "./Modal";
import AddProductModal from "./AddProductModal";
import FeedbackModal from "./FeedbackModal";

import * as SidebarUtils from "utils/sidebar";

function desc(a, b, orderBy) {
  let compA = a[orderBy].toLowerCase();
  let compB = b[orderBy].toLowerCase();
  if (
    !isNaN(parseFloat(compA.toString().replace(/\$|,/g, ""))) &&
    !isNaN(parseFloat(compB.toString().replace(/\$|,/g, "")))
  ) {
    compA = parseFloat(compA.toString().replace(/\$|,/g, ""));
    compB = parseFloat(compB.toString().replace(/\$|,/g, ""));
  }
  if (compB < compA) {
    return -1;
  }
  if (compB > compA) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

// const minDate60Days = moment().subtract(60, 'days');
// function getMinDate(date) {
//   return minDate60Days.isAfter(moment(date)) ? minDate60Days.toDate() : date;
// }

function getProductLabel(option, isUploader) {
  if (!isUploader) {
    if (option.name.startsWith("Ungated")) {
      if (option.name === "Ungated A" || option.name === "Ungated B") {
        return "Ungated Plus";
      } else {
        return "Ungated";
      }
    }
    if (option.name.startsWith("Replens")) {
      return "Replens";
    }
  } else {
    if (option.name === "Replens splitted 1") {
      return "Replens A";
    }
    if (option.name === "Replens splitted 2") {
      return "Replens B";
    }
  }

  return option.name;
}

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, rows } =
      this.props;
    const hiddenSort = ["Product Link", "Amazon Link"];
    return (
      <TableHead>
        <TableRow style={{ height: "45px", maxHeight: "45px" }}>
          {/* <TableCell padding='none'>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              color='primary'
              inputProps={{
                "aria-label": "secondary checkbox",
              }}
            />
          </TableCell> */}
          <TableCell padding="none"></TableCell>

          {rows.map(
            (row, i) => (
              <TableCell
                style={{ fontSize: "14px" }}
                key={row}
                align={"center"}
                padding={"none"}
                sortDirection={orderBy === row ? order : false}
              >
                <TableSortLabel
                  hideSortIcon={orderBy !== row}
                  active={orderBy === row}
                  direction={order}
                  onClick={
                    hiddenSort.includes(row)
                      ? null
                      : this.createSortHandler(row)
                  }
                >
                  <strong>{row.replace(/"([^"]+(?="))"/g, "$1")}</strong>
                </TableSortLabel>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    fontFamily: "Quicksand,serif",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
    aligh: "center",
  },
  search: {
    display: "inline",
    marginLeft: "auto",
    marginRight: "20px",
  },
});

let EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    classes,
    value,
    addToInventory,
    handleSearch,
    rowCount,
    loadingPercent,
    loadingPhrase,
    listName,
    postBulkBuy,
    checkedA,
    handleChange,
    anchorEl,
    handleClose,
    handleDropDown,
    selected,
    addDirectToInventory,
  } = props;
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle">
        <div style={{ display: "inline-block" }}>{listName}</div>
      </Typography>
      {numSelected < 1 ? null : (
        <AddProductModal
          numSelected={numSelected}
          selected={selected}
          addToInventory={addToInventory}
        />
      )}

      <div align="right" className={classes.search}>
        <TextField placeholder="Search" onChange={handleSearch} value={value} />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const lists = [
  { id: 1, name: "Ungated A", price: 15900 },
  { id: 2, name: "Ungated B", price: 15900 },
  { id: 3, name: "Ungated C", price: 15900 },
  { id: 4, name: "Ungated D", price: 15900 },
  { id: 5, name: "Ungated E", price: 10500 },
  { id: 6, name: "Ungated F", price: 10500 },
  { id: 7, name: "Ungated G", price: 10500 },
  { id: 8, name: "Ungated H", price: 10500 },
  { id: 9, name: "Ungated I", price: 10500 },
  { id: 10, name: "Ungated J", price: 10500 },
  { id: 11, name: "Ungated K", price: 10500 },
  { id: 12, name: "Ungated L", price: 10500 },
  { id: 13, name: "Ungated M", price: 10500 },
  { id: 14, name: "Replens splitted 1", price: 15900 },
  { id: 15, name: "Replens splitted 2", price: 15900 },
  { id: 16, name: "Turbo Gated", price: 13900 },
];
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing.unit * 2,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  button: {
    // height:'30px',
    //marginTop:'-3px',
    border: "0px",
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "20px",
    // padding:"2px",
    marginLeft: "10px",
    backgroundColor: "#149cea",
    "&:hover": {
      border: "0px",
      backgroundColor: "#132f4a",
    },
    "&:disabled": {
      border: "0px",
      color: "white",
      backgroundColor: "#919EAA",
    },
    color: "white",
    minHeight: "38px",
    // "&:hover": {
    //   color: "white"
    // },
  },
  link: {
    color: "blue",
    textDecoration: "underline",
  },
  dateWrapper: {
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    fontWeight: "normal",
  },
  table: {
    tableLayout: "auto",
    overflowX: "scroll",
  },
  cell: {
    whiteSpace: "wrap",
    lineHeight: "15px",
    height: "45px",
    textOverflow: "hidden",
    minWidth: "50px",
    maxWidth: "150px",
    overflow: "hidden",
    padding: "2px",
    border: "1px solid #dddddd",
    maxHeight: "45px",
  },
  innerCell: {
    whiteSpace: "wrap",
    lineHeight: "15px",
    minWidth: "32px",
    height: "45px",
    textOverflow: "hidden",
    maxWidth: "150px",
    overflow: "hidden",
    maxHeight: "45px",
  },
  row: {
    height: "45px",
    maxHeight: "45px",
  },
  select: {
    display: "inline-block",
    minWidth: "165px",
    marginRight: "12px",
  },
  datepicker: {
    backgroundColor: "hsl(0, 0%, 100%)",
    borderColor: "hsl(0, 0%, 80%)",
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "1px",
    padding: "2px 8px",
    minHeight: "38px",
  },
  oaleadsRow: {
    display: "flex",
    alignItems: "center",
  },
});

class Oaleads extends Component {
  constructor(props) {
    super(props);
    const todaysdate =
      new Date().getMonth() +
      1 +
      "-" +
      new Date().getDate() +
      "-" +
      new Date().getFullYear();
    this.state = {
      chosen_list: "Ungated A",
      chosen_price: 13900,
      startDate: new Date(),
      date: todaysdate,
      endDate: new Date(),
      errors: {},
      csv: null,
      leads: null,
      prev_leads: null,
      lists: null,
      multiChecked: false,
      multiUpload: false,
      header: null,
      tableData: null,
      anchorEl: null,
      dateChanged: true,
      order: "desc",
      orderBy: "Net Profit",
      selected: [],
      inventory: [],
      filteredLeads: [],
      page: 0,
      rowsPerPage: 50,
      query: "none",
      checkedA: false,
      anchorEl: null,
      feedSubmissionId: null,
      loadingPercent: null,
      loadingPhrase: "",
      minDateSub: new Date(),
      activeOption: null,
      hasUngated: false,
      hasReplens: false,
      selectForPurchase: null,
    };
    this.allListTranslate = {};
    this.handleDateChange = this.handleDateChange.bind(this);
    this.getLeads = this.getLeads.bind(this);
    // this.handleListChange = this.handleListChange.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.onToken = this.onToken.bind(this);
  }

  handleDateChange(e) {
    this.setState({
      dateChanged: true,
      startDate: new Date(e),
    });
  }

  downloadFile() {
    let startDate =
      " " +
      (new Date(this.props.leads.leads.startDate).getMonth() + 1) +
      "-" +
      new Date(this.props.leads.leads.startDate).getDate() +
      "-" +
      new Date(this.props.leads.leads.startDate).getFullYear();
    let fileName =
      (this.allListTranslate[this.props.leads.leads.flavor] ||
        this.props.leads.leads.flavor) +
      startDate +
      ".csv";
    if (this.props.leads.leads.multi) {
      let endDate =
        new Date(this.props.leads.leads.endDate).getMonth() +
        1 +
        "-" +
        new Date(this.props.leads.leads.endDate).getDate() +
        "-" +
        new Date(this.props.leads.leads.endDate).getFullYear();
      fileName =
        (this.allListTranslate[this.props.leads.leads.flavor] ||
          this.props.leads.leads.flavor) +
        startDate +
        " to " +
        endDate +
        ".csv";
    }
    let urlData =
      "data:text/csv;charset=UTF-8," +
      encodeURIComponent(this.props.leads.leads.csv);
    var aLink = document.createElement("a");
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent("click");
    aLink.download = fileName;
    aLink.href = urlData;
    aLink.dispatchEvent(evt);
    aLink.click();
  }

  componentDidMount() {
    this.props.fetchSubscriptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors,
      });
    }
    if (prevProps.auth.data !== this.props.auth.data) {
      if (this.props.auth.data.success) {
        this.setState({
          selected: [],
        });
        toast.info(
          <div>
            We are attempting to add the product(s) to your Inventory. There
            should be no issues unless you are gated or they are hazmat. Check
            your Inventory page in <strong> 3-5 minutes </strong> to ensure they
            were successully added.
            <br />
            <br />
            DO NOT PURCHASE PRODUCTS UNTIL THEY ARE SHOWN IN YOUR INVENTORY
            PAGE.
          </div>,
          {
            autoClose: false,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
    }
    if (prevProps.auth.subs !== this.props.auth.subs) {
      if (this.props.auth.subs.length > 0) {
        let initialSub = this.props.auth.subs.find(
          (s) =>
            s.flavor !== "Software Suite" &&
            (!s.cancel_at || moment(s.cancel_at).diff(new Date()) > 0)
        );
        if (initialSub && initialSub.flavor) {
          if (initialSub.flavor.startsWith("Ungated")) {
            initialSub = this.props.auth.subs
              .filter((s) => s.flavor.startsWith("Ungated"))
              .sort((a, b) => a.flavor.localeCompare(b.flavor))
              .shift();
          }

          const activeOption = lists.find(
            (i) =>
              (i.name.startsWith("Turbo") ? "Turbo" : i.name) ===
              initialSub.flavor
          );
          const minDateSub = new Date(initialSub.datesubbed);
          const isUploader =
            this.props.auth.user.email === "upload.leads@go-atlas.io" ||
            this.props.auth.user.admin === true;
          this.setState(
            {
              chosen_list: initialSub.flavor,
              activeOption: activeOption
                ? {
                    key: activeOption.id,
                    label: getProductLabel(activeOption, isUploader),
                    value: activeOption.name,
                  }
                : null,
              minDateSub,
              newSub: SidebarUtils.isNewSub(minDateSub),
              hasUngated: this.props.auth.subs.some(
                (s) => s.flavor && s.flavor.startsWith("Ungated")
              ),
              hasReplens: this.props.auth.subs.some(
                (s) => s.flavor && s.flavor.startsWith("Replens")
              ),
            },
            () => {
              this.getLeads("viewleads");
            }
          );
        }
      }
    }
    if (prevProps.leads !== this.props.leads) {
      this.setState({
        leads: this.props.leads,
      });
      if (this.props.leads.leads.success && !this.props.leads.loading) {
        return toast.success(
          "Leads uploaded for " +
            this.props.leads.leads.flavor +
            " for date: " +
            this.props.leads.leads.date.slice(0, 10)
        );
      }
      if (this.props.leads.leads.csv) {
        let parsedData = this.csvToJson(this.props.leads.leads.csv);
        //remove extra headers/empty spots
        for (var i = parsedData.body.length - 1; i >= 0; i--) {
          if (!parsedData.body[i].Store) parsedData.body.splice(i, 1);
          else if (
            parsedData.body[i].Store &&
            parsedData.body[i].Store === "Store"
          )
            parsedData.body.splice(i, 1);
          else if (
            parsedData.body[i].Store &&
            parsedData.body[i].Store.length <= 1
          )
            parsedData.body.splice(i, 1);
        }
        this.setState({
          dateChanged: false,
          csv: this.props.leads.leads.csv,
          tableData: parsedData.body,
          header: parsedData.header,
          filteredLeads: parsedData.body,
        });
      }
    }
  }

  getLeads(type) {
    if (type === "getleads") {
      this.downloadFile();
      return 0;
    }
    let leadData = {
      multi: this.state.multiChecked,
      flavor:
        this.state.chosen_list === "Turbo Gated"
          ? "Turbo"
          : this.state.chosen_list,
      endDate:
        new Date(this.state.endDate).getFullYear() +
        "/" +
        (new Date(this.state.endDate).getMonth() + 1) +
        "/" +
        new Date(this.state.endDate).getDate(),
      startDate:
        new Date(this.state.startDate).getFullYear() +
        "/" +
        (new Date(this.state.startDate).getMonth() + 1) +
        "/" +
        new Date(this.state.startDate).getDate(),
    };
    this.setState({ csv: null, selected: [] });

    this.props.getLeads(leadData, this.props.history);
  }

  uploadLeads = () => {
    if (this.state.multiUpload) {
      for (let i = 0; i < lists.length; i++) {
        let leadData = {
          multi: this.state.multiUpload,
          flavor: lists[i].name,
          startDate:
            new Date(this.state.startDate).getFullYear() +
            "/" +
            (new Date(this.state.startDate).getMonth() + 1) +
            "/" +
            new Date(this.state.startDate).getDate(),
        };
        this.props.uploadLeads(leadData, this.props.history);
      }
    } else {
      let leadData = {
        multi: this.state.multiUpload,
        flavor: this.state.chosen_list,
        startDate: new Date(this.state.startDate).toLocaleString().slice(0, 10),
      };
      this.props.uploadLeads(leadData, this.props.history);
    }
  };

  onToken(token, addresses) {
    let data = {
      email: this.props.auth.user.email,
      name: this.props.auth.user.name,
      flavor:
        this.state.chosen_list === "Turbo Gated"
          ? "Turbo"
          : this.state.chosen_list,
      token,
    };
    this.props.submitPayment(data);
  }

  handleChange = (e) => {
    this.setState({
      dateChanged: true,
      [e.target.value]: !this.state[e.target.value],
    });
  };

  setNewDates = (startDate, endDate) => {
    startDate = startDate.setHours(0, 0, 0, 0);
    endDate = endDate.setHours(0, 0, 0, 0);
    if (startDate > endDate) {
      toast.error("Start Date must be older than End Date!");
      return 0;
    }
    this.setState({
      dateChanged: true,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    });
  };

  csvToJson = (csv) => {
    const [firstLine, ...lines] = csv.split("\n");
    const re = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;
    const body = lines.map((line) =>
      firstLine.split(re).reduce(
        (curr, next, index) => ({
          ...curr,
          [next]: line.split(re)[index]
            ? line.split(re)[index].replace(/"([^"]+(?="))"/g, "$1")
            : "",
        }),
        {}
      )
    );
    return { body, header: firstLine.split(re) };
  };

  addToInventory = (asinList) => {
    if (!this.props.auth.user.sellerID)
      return toast.error(
        "You need to add your Amazon Account - go to Settings!"
      );
    if (!asinList) {
      asinList = [];
      for (let i = 0; i < this.state.selected.length; i++) {
        if (this.state.selected[i].ASIN)
          asinList.push({
            notes:
              this.state.selected[i]['"Variations,pack,size & Other Comment"'],
            asin: this.state.selected[i].ASIN,
            buyPrice: parseFloat(
              this.state.selected[i]["Buy Price"]
                .toString()
                .replace(/\$|,/g, "")
            ),
            listPrice: parseFloat(
              this.state.selected[i]["Amazon Price"]
                .toString()
                .replace(/\$|,/g, "")
            ),
            buyLink: this.state.selected[i]["Product Link"],
            supplier: this.state.selected[i]["Store"],
          });
      }
    }
    this.props.addToInventory(asinList);
  };

  handleDropDown = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleSearch = (event) => {
    let input, filter, a, i, txtValue, filteredData;
    input = event.target.value;
    filter = event.target.value.toUpperCase();
    filteredData = [];
    const { tableData } = this.state;
    for (i = 0; i < tableData.length; i++) {
      a = tableData[i];
      txtValue = JSON.stringify(a);
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        filteredData.push(tableData[i]);
      }
    }
    this.setState({
      filteredLeads: filteredData,
      query: input,
    });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({
      order,
      orderBy,
    });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState((state) => ({
        selected: state.filteredLeads.map((n) => n),
      }));
      return;
    }
    this.setState({
      selected: [],
    });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({
      selected: newSelected,
    });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  onChangeList = (option) => {
    if (option.value !== this.state.chosen_list) {
      if (option.subscribed) {
        const price = option.price;
        const chosen_list = option.value;
        const { subs } = this.props.auth;
        let minDateSub = new Date();
        if (subs && subs.length > 0) {
          const sub = subs.find((s) => chosen_list.startsWith(s.flavor));
          if (sub) {
            minDateSub = new Date(sub.datesubbed);
          }
        }
        this.setState({
          chosen_list,
          chosen_price: price,
          dateChanged: true,
          minDateSub,
          errors: {},
          activeOption: option,
          newSub: SidebarUtils.isNewSub(minDateSub),
        });
      } else {
        let selectForPurchase = "Ungated";
        if (option.value.startsWith("Replen")) {
          selectForPurchase = "Replenishables";
        }
        if (option.value.startsWith("Turbo")) {
          selectForPurchase = "Turbo";
        }
        this.setState({
          errors: {},
          selectForPurchase,
        });
      }
    }
  };

  colourStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        color: !data.subscribed ? "#908c8c" : styles.color,
      };
    },
    menu: (provided, state) => ({
      ...provided,
      width: "200%",
    }),
  };

  render() {
    const { classes, leads, auth } = this.props;
    const {
      errors,
      anchorEl,
      inventory,
      filteredLeads,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      hasUngated,
      hasReplens,
      activeOption,
      newSub,
    } = this.state;
    const { user, pending, loadingSubs } = this.props.auth;
    const isUploader =
      user.email === "upload.leads@go-atlas.io" || user.admin === true;
    const errorTest = Object.keys(errors).length;
    const csv = this.props.leads.leads.csv;
    let defaultIndex = 0;
    const hasSubs = isUploader || (auth.subs && auth.subs.length > 0);
    let indexSet = false;
    let totalUngated = 0;
    let replensFiltered = false;
    this.allListTranslate = {};
    const allLists = lists
      .filter((item) => {
        let name = item.name.split(" ")[0];
        name = name.startsWith("Turbo") ? "Turbo" : name;
        if (!isUploader && name === "Ungated" && !hasUngated) {
          return false;
        }
        if (!isUploader && !hasReplens && name.startsWith("Replens")) {
          if (replensFiltered) {
            return false;
          } else {
            replensFiltered = true;
            return true;
          }
        }
        if (!isUploader && auth.subs) {
          const sub = auth.subs.find(
            (s) =>
              s.flavor === item.name &&
              (!s.cancel_at || moment(s.cancel_at).diff(new Date()) > 0)
          );
          if (!sub && name === "Ungated") {
            return false;
          }
          if (!sub && hasReplens && item.name.startsWith("Replens")) {
            return false;
          }
          return (
            !sub ||
            sub.flavor === (item.name.startsWith("Turbo") ? "Turbo" : item.name)
          );
        }
        return true;
      })
      .map((item, idx) => {
        let subscribed = true;
        if (!isUploader && auth.subs) {
          const sub = auth.subs.find(
            (s) =>
              s.flavor === (item.name.startsWith("Turbo") ? "Turbo" : item.name)
          );

          subscribed = !sub ? false : sub.ended_at === null;
          if (!indexSet && subscribed) {
            defaultIndex = idx;
            indexSet = true;
          }
        }
        let label = item.name;
        if (!isUploader && label.startsWith("Ungated")) {
          if (label == "Ungated A" || label == "Ungated B") {
            const previousLabel = label;
            label = label;
            if (totalUngated >= 1) {
              label += ` (${totalUngated})`;
            }
            this.allListTranslate[previousLabel] = label;
            totalUngated += 1;
          } else {
            const previousLabel = label;
            label = "Ungated";
            if (totalUngated >= 1) {
              label += ` (${totalUngated})`;
            }
            this.allListTranslate[previousLabel] = label;
            totalUngated += 1;
          }
        }
        if (!isUploader && label.startsWith("Replens")) {
          this.allListTranslate[label] = "Replens";
          label = "Replens";
        }
        if (
          (label.startsWith("Turbo") || label.startsWith("Replens")) &&
          !subscribed
        ) {
          label += " (premium - click for more)";
        }
        if (isUploader && label === "Replens splitted 1") {
          this.allListTranslate[label] = "Replens A";
          label = "Replens A";
        }
        if (isUploader && label === "Replens splitted 2") {
          this.allListTranslate[label] = "Replens B";
          label = "Replens B";
        }
        return {
          label,
          value: item.name,
          price: item.price,
          key: item.id,
          subscribed,
        };
        // return (
        //   <option key={item.id} price={item.price} value={item.name} disabled={disabled}>
        //     {item.name}
        //   </option>
        // )
      });

    // if (isUploader || user.tier < 1) {
    //   allLists.unshift({
    //     value: 'Rookie',
    //     label: 'Rookie',
    //     price: 0,
    //     key: 0,
    //     subscribed: true,
    //   });
    // }

    let stripeKey;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      stripeKey = "pk_test_yTxtNK50fnYQ20mawyqVWY2y";
    } else {
      stripeKey = "pk_live_aszZgWDTsAm84X8YDj7N1H0i";
    }

    let leadsContent,
      newSubContent,
      loadingLeads,
      purchasePending,
      tableContent,
      uploadContent,
      purchaseContent;
    if (pending) {
      purchasePending = (
        <div>
          Checking on that...
          <img
            src={spinner}
            style={{ width: "30px", margin: "0", display: "inline" }}
            alt="Loading..."
          />
        </div>
      );
    } else purchasePending = null;

    newSubContent = errors.subscription ? (
      <div style={{ color: "red" }}>{errors.subscription}</div>
    ) : null;

    if (leads.loading && errorTest === 0) {
      loadingLeads = (
        <img
          src={spinner}
          style={{ width: "30px", margin: "0", display: "inline" }}
          alt="Loading..."
        />
      );
    } else {
      loadingLeads = null;
    }
    if (isUploader) {
      uploadContent = (
        <div id="boughtuser">
          <div>
            Select a date and list type to <strong> upload </strong>into the
            database
          </div>
          <Select
            isLoading={loadingSubs}
            value={
              !loadingSubs && hasSubs
                ? activeOption || allLists[defaultIndex]
                : null
            }
            options={allLists}
            styles={this.colourStyles}
            onChange={this.onChangeList}
            className={classes.select}
          />
          {/* <select
            className="leadssel"
            value={this.state.chosen_list}
            onChange={this.handleListChange}
          >
            {allLists}
          </select> */}
          <span className="oaleadDates">
            <DatePicker
              selected={this.state.startDate}
              onChange={this.handleDateChange}
              disabled={leads.loading}
              className={classes.datepicker}
            />
          </span>
          <Button
            id="uploadLeads"
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.uploadLeads}
            disabled={(leads.loading && errorTest === 0) || loadingSubs}
          >
            Upload Leads
          </Button>
          <br />
          Upload ALL lists for this day?
          <Checkbox
            checked={this.state.multiUpload}
            onChange={this.handleChange}
            disabled={leads.loading}
            value="multiUpload"
            color="primary"
            inputProps={{
              "aria-label": "secondary checkbox",
            }}
          />
          {loadingLeads}
          {leads.loading && errorTest === 0 ? "Uploading the Leads..." : null}
          {errorTest === 0 && leads.leads.csv != null && !leads.loading
            ? leads.leads.flavor + " uploaded!"
            : null}
        </div>
      );
    }
    if (
      errorTest === 0 &&
      this.state.tableData !== null &&
      this.state.header !== null &&
      !this.state.dateChanged &&
      !leads.loading
    ) {
      tableContent = (
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            loadingPercent={this.state.loadingPercent}
            loadingPhrase={this.state.loadingPhrase}
            listName={
              this.allListTranslate[leads.leads.flavor] || leads.leads.flavor
            }
            selected={this.state.selected}
            addToInventory={this.addToInventory}
            addDirectToInventory={this.props.addToInventory}
            handleDropDown={this.handleDropDown}
            handleClose={this.handleClose}
            anchorEl={anchorEl}
            numSelected={selected.length}
            handleSearch={this.handleSearch}
            value={this.searchValue}
            checkedA={this.state.checkedA}
            handleChange={this.handleChange}
            rowCount={filteredLeads.length}
          />
          <div
            style={{
              maxWidth: "100%",
              overflowX: "auto",
              border: "1px solid #dddddd",
            }}
          >
            <Table className={classes.table}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={this.state.filteredLeads.length}
                className={classes.cell}
                rows={this.state.header}
              />
              <TableBody>
                {stableSort(
                  this.state.filteredLeads,
                  getSorting(order, orderBy)
                ).map((row, i) => {
                  const isSelected = this.isSelected(row);
                  return (
                    <TableRow
                      key={i}
                      style={{
                        backgroundColor: isSelected
                          ? "pink"
                          : i % 2 === 0
                          ? "#f7fbff"
                          : "",
                      }}
                      className={classes.row}
                    >
                      {/* <TableCell padding='none'>
                        <Checkbox
                          color='primary'
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                          onClick={(event) => this.handleClick(event, row)}
                          checked={this.isSelected(row)}
                        />
                      </TableCell> */}
                      <td
                        align="center"
                        style={{
                          padding: 2,
                          borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        <FeedbackModal
                          flavor={
                            this.allListTranslate[leads.leads.flavor] ||
                            leads.leads.flavor
                          }
                          user={user}
                          row={row}
                          sendFeedback={this.props.sendFeedback}
                        />
                      </td>

                      {Object.keys(this.state.filteredLeads[i]).map(
                        (cell, k) => {
                          let cellContent;
                          if (row[cell].toString().startsWith("http")) {
                            cellContent = (
                              <a
                                target="_blank"
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                }}
                                href={row[cell]}
                              >
                                {cell === "Product Link"
                                  ? "Buy Here"
                                  : "Sell Here"}
                              </a>
                            );
                          } else if (
                            (cell === "Estimated Sales/Month" ||
                              cell === "Current Rank") &&
                            row[cell] !== ""
                          ) {
                            cellContent = (
                              <div align="center">
                                {row[cell]}
                                <FormDialog asin={row["ASIN"]} />
                              </div>
                            );
                          } else cellContent = row[cell];
                          return (
                            <TableCell
                              className={classes.cell}
                              key={"" + i + k}
                              align="center"
                            >
                              <div className={classes.innerCell}>
                                {cellContent}
                              </div>
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </Paper>
      );
    }

    const dateErrorAndNewSub =
      newSub && errors.date === "List not available for that date.";

    leadsContent = (
      <Paper className={classes.paper}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          style={{ textAlign: "left" }}
        >
          Download OA Leads
        </Typography>
        <div id="boughtuser">
          <div>
            Select a date and list type to fetch your leads for that day!
            <br />
            {/* <select
              className="leadssel"
              value={this.state.chosen_list}
              onChange={this.handleListChange}
            >
              {allLists}
            </select> */}
            <div className={classes.oaleadsRow}>
              <Select
                isLoading={loadingSubs}
                value={
                  !loadingSubs && hasSubs
                    ? activeOption || allLists[defaultIndex]
                    : null
                }
                options={allLists}
                styles={this.colourStyles}
                onChange={this.onChangeList}
                className={classes.select}
              />
              {this.state.multiChecked ? (
                <div className={classes.dateWrapper}>
                  <span className="oaleadDates">
                    <DatePicker
                      disabled={leads.loading || loadingSubs}
                      className={classes.datepicker}
                      selected={this.state.startDate}
                      maxDate={this.state.endDate}
                      minDate={!isUploader && this.state.minDateSub}
                      onChange={(e) => {
                        this.setNewDates(e, this.state.endDate);
                      }}
                    />
                  </span>
                  <div
                    style={{ margin: "0 4px" }}
                    className={classes.dateWrapper}
                  >
                    <i className="fas fa-arrow-right"></i>
                  </div>
                  <div className={classes.dateWrapper}>
                    <span className="oaleadDates">
                      <DatePicker
                        disabled={leads.loading || loadingSubs}
                        className={classes.datepicker}
                        selected={this.state.endDate}
                        minDate={this.state.startDate}
                        // maxDate={
                        //   new Date(
                        //     new Date().getFullYear(),
                        //     new Date().getMonth(),
                        //     new Date().getDate() + 2
                        //   )
                        // }
                        onChange={(e) => {
                          this.setNewDates(this.state.startDate, e);
                        }}
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <span className="oaleadDates">
                  <DatePicker
                    minDate={!isUploader && this.state.minDateSub}
                    selected={this.state.startDate}
                    onChange={this.handleDateChange}
                    disabled={loadingSubs}
                    className={classes.datepicker}
                  />
                </span>
              )}
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={() => this.getLeads("viewleads")}
                disabled={(leads.loading && errorTest === 0) || loadingSubs}
              >
                Fetch Leads
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={() => this.getLeads("getleads")}
                disabled={(leads.loading && errorTest === 0) || loadingSubs}
                style={{ display: this.state.dateChanged ? "none" : "" }}
              >
                Download Table as .CSV
              </Button>
            </div>
          </div>
          Would you like to download multiple days?
          <Checkbox
            checked={this.state.multiChecked}
            onChange={this.handleChange}
            disabled={leads.loading || loadingSubs}
            value="multiChecked"
            color="primary"
            inputProps={{
              "aria-label": "secondary checkbox",
            }}
          />
          {loadingLeads}
          {purchasePending}
          {leads.loading && errorTest === 0
            ? "Fetching Your Leads... This may take a while if you chose a large date range"
            : null}
          {errorTest === 0 &&
          leads.leads.csv != null &&
          !leads.loading &&
          !this.state.dateChanged
            ? (this.allListTranslate[leads.leads.flavor] ||
                leads.leads.flavor) + " fetched!"
            : null}
          {newSubContent}
          <div style={{ color: "red" }}>
            {errors.user}
            {errors.leads}
            {!dateErrorAndNewSub && errors.date}
            {errors.other}
            {errors.payment}
          </div>
          {uploadContent}
          {this.state.chosen_list === "Rookie" ? (
            <strong style={{ color: "red", fontSize: "14px" }}>
              *Rookie leads are only released on{" "}
              <span style={{ textDecoration: "underline" }}>Tuesdays</span>{" "}
              around 11 a.m. CST.
              <br /> For up to 15x more leads, less competition , and other
              perks, upgrade to a full list :)*
            </strong>
          ) : (
            <div
              style={{
                fontSize: "14px",
                color: dateErrorAndNewSub ? "red" : undefined,
              }}
            >
              *Online arbitrage leads are released Monday-Friday at
              {` ${
                SidebarUtils.COUNTDOWN_OPTIONS.hours12 +
                SidebarUtils.COUNTDOWN_OPTIONS.ampm
              } `}
              EST (Eastern Standard Time) GMT -5*
            </div>
          )}
        </div>
      </Paper>
    );
    purchaseContent = (
      <Paper
        className={classes.paper}
        style={{ marginBottom: 24, textAlign: "center" }}
      >
        <PurchaseModal
          auth={this.props.auth}
          errors={errors}
          selectForPurchase={this.state.selectForPurchase}
          onClose={() => this.setState({ selectForPurchase: null })}
        />
      </Paper>
    );

    return (
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item md={8} sm={12}>
            {leadsContent}
          </Grid>
          <Grid item md={4} sm={12} style={{ minHeight: "190px" }}>
            {purchaseContent}
          </Grid>
          <Grid item xs={12}>
            {tableContent}
          </Grid>
        </Grid>
      </div>
    );
  }
}

Oaleads.propTypes = {
  fetchSubscriptions: PropTypes.func.isRequired,
  leads: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  leads: state.leads,
  errors: state.errors,
});

export default withRouter(
  connect(mapStateToProps, {
    getLeads,
    submitPayment,
    uploadLeads,
    addToInventory,
    checkFeed,
    sendFeedback,
    fetchSubscriptions,
  })(withStyles(styles)(Oaleads))
);
