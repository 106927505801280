import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as qs from "query-string";
import ReactCardFlip from "react-card-flip";
import {
  Grid,
  Paper,
  Typography,
  Menu,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import {
  StripeProvider,
  Elements,
  injectStripe,
  CardElement,
} from "react-stripe-elements";

// Actions
import { createLead, submitNewPayment } from "../../actions/authActions";
import { getPublicUngatingTypes } from "../../actions/ungatingActions";

// Components
import TextFieldGroup from "../common/TextFieldGroup";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 0,
      md: 768,
      lg: 900,
      xl: 1200,
    },
  },
});

const styles = (theme) => ({
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardHeaderTitle: {
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  cardHeaderTitleText: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  paper: {
    minHeight: "1px",
    maxHeight: "1000px",
    [theme.breakpoints.up(768)]: {
      minHeight: "383px",
    },

    position: "relative",
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    border: "1px solid rgba(9,9,9,.1)",
    borderRadius: "4px",
    boxShadow: "none",
  },
  button: {
    width: "100%",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "normal",
    padding: "10px",
    margin: "0px",
    backgroundColor: "#149cea",
    "&:hover": {
      color: "#132f4a",
    },
    "&:disabled": {
      color: "white",
      backgroundColor: "#919EAA",
    },
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
  planButton: {
    position: "absolute",
    right: "16px",
    textTransform: "none",
    fontSize: "13px",
    fontWeight: "normal",
    padding: "3px",
    color: "#149cea",
    borderColor: "#149cea",
    "&:hover": {
      color: "#132f4a",
      borderColor: "#132f4a",
    },
    "&:disabled": {
      color: "grey",
      backgroundColor: "#919EAA",
    },
    "&:hover": {
      color: "#132f4a",
    },
  },
  headline: {
    fontFamily: "Raleway",
    fontSize: "16px",
    textAlign: "left",
  },
  link: {
    textDecoration: "underline",
    "&:hover": {
      color: "#132f4a",
    },
  },
  linkContainer: {
    marginTop: "15px",
    marginBottom: "15px",
  },
});

class _CardForm extends React.Component {
  constructor() {
    super();
    this.state = {
      terms: false,
      termError: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    if (!this.state.terms) {
      this.setState({ termError: "Please accept the Terms and Conditions" });
      return;
    }
    if (this.props.cardholder.length < 1 || this.props.email.length < 1) {
      this.setState({ termError: "Please add name and email" });
      return;
    }
    this.setState({ termError: "" }, () => {
      this.props.stripe
        .createToken({
          name: this.props.cardholder,
          email: this.props.email.toLowerCase(),
        })
        .then((payload) => {
          this.props.submitNewPayment(payload);
        });
    });
  };

  render() {
    const { errors, pending, classes } = this.props;
    const { termError, terms } = this.state;

    return (
      <>
        <div style={{ width: "100%" }}>
          <CardElement
            disabled={pending}
            className="form-control"
            {...this.props}
            style={{
              base: {
                fontFamily: "Quicksand",
                fontSize: "17px",
                lineHeight: "1.429",
              },
            }}
          />
        </div>
        {errors.card || errors.payment ? (
          <small className="text-danger">
            {errors.card} {errors.payment}
          </small>
        ) : null}
        {termError !== "" ? (
          <small className="text-danger">{termError}</small>
        ) : null}
        <br />
        <div className={classes.linkContainer}>
          <Checkbox
            checked={terms}
            onChange={this.onChange}
            name="terms"
            color="primary"
            inputProps={{
              "aria-label": "terms checkbox",
            }}
            style={{
              color: "#029cea",
            }}
            id="terms_checkbox"
          />

          <label htmlFor="terms_checkbox">
            Accept{" "}
            <a
              target="_blank"
              className={classes.link}
              href="https://www.go-atlas.io/atlas-terms-of-service/"
            >
              {" "}
              Terms{" "}
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              className={classes.link}
              href="https://www.go-atlas.io/atlas-access-terms-of-service/"
            >
              {" "}
              Ungating Policy{" "}
            </a>
          </label>
        </div>
        <div className="wp-button-container">
          <button
            onClick={this.handleSubmit}
            type="button"
            className="wp-style-button primary"
          >
            Confirm Order
          </button>
        </div>
      </>
    );
  }
}

const CardForm = injectStripe(_CardForm);

class CheckoutUngating extends Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      cardholder: "",
      email: "",
      password: "",
      promoCode: "",
      promoError: "",
      aaid: "",
      categoryOrBrandId: "",
      categoryOrBrand: {
        _id: null,
        name: null,
        benefits: null,
        cost: null,
      },
      errors: {},
      emailGiven: false,
      goodPromo: false,
      isFlipped: false,
      stripe: null,
      anchorEl: null,
      ungatings: [],
    };

    this.onChange = this.onChange.bind(this);
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return undefined;
  };

  setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  componentDidMount() {
    document.body.classList.add("hide-fc");
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      if (window.Stripe) {
        this.setState({
          stripe: window.Stripe("pk_test_yTxtNK50fnYQ20mawyqVWY2y"),
        });
      }
    } else {
      if (window.Stripe) {
        this.setState({
          stripe: window.Stripe("pk_live_aszZgWDTsAm84X8YDj7N1H0i"),
        });
      }
    }

    const { location } = this.props;

    const parsed = qs.parse(location.search);
    let { email, firstName, lastName, categoryOrBrandId, emailGiven } =
      this.state;
    if (parsed.email) {
      email = parsed.email.toLowerCase();
      emailGiven = true;
    }
    if (parsed.firstName) firstName = parsed.firstName;
    if (parsed.lastName) lastName = parsed.lastName;
    if (parsed.categoryOrBrandId) categoryOrBrandId = parsed.categoryOrBrandId;
    const aaid = this.getCookie("AAID");
    this.setState({
      email,
      firstName,
      lastName,
      categoryOrBrandId,
      emailGiven,
      aaid,
    });
    this.props.getPublicUngatingTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors,
      });
      if (this.props.errors.email || this.props.errors.name) {
        this.setState({
          isFlipped: false,
        });
      }
    }
    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth.data && this.props.auth.data.flip) {
        if (!this.state.isFlipped) {
          this.setState({ isFlipped: true });
        }
        window.fbq("track", "UngatingLead"); //facebook pixel lead
      }
    }
    if (prevProps.ungating.ungatings !== this.props.ungating.ungatings) {
      const { ungatings } = this.props.ungating;
      const { categoryOrBrandId } = this.state;

      let categoryOrBrand = ungatings.find(
        (ungating) => ungating._id === categoryOrBrandId
      );
      if (!categoryOrBrand) {
        if (ungatings.length > 0) {
          categoryOrBrand = ungatings[0];
        } else {
          categoryOrBrand = {
            _id: null,
            name: null,
            benefits: null,
            cost: null,
          };
        }
      }

      this.setState({
        ungatings,
        categoryOrBrand,
      });
    }
  }

  onChange(e) {
    if (e.target.name === "promoCode") {
      this.setState({ [e.target.name]: e.target.value }, () => {
        this.checkPromoCode();
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  checkPromoCode = () => {
    this.setState({
      goodPromo: false,
      promoError: "",
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    //step 1 - user Information
    const { firstName, lastName, email, password, aaid } = this.state;
    const newUser = {
      ungating: true,
      name: `${firstName} ${lastName}`,
      email: email.toLowerCase(),
      password: password,
      password2: password,
      aaid: aaid,
    };
    this.props.createLead(newUser, this.props.history);
  };

  submitNewPayment = (token) => {
    //step 2 payment Information
    let errors = {};
    if (token.error) {
      errors.card = token.error.message;
    }
    this.setState({ errors });
    if (Object.keys(errors).length !== 0) {
      return;
    }

    const {
      firstName,
      lastName,
      email,
      password,
      categoryOrBrand,
      promoCode,
      aaid,
    } = this.state;
    const newUser = {
      name: `${firstName} ${lastName}`,
      email: email.toLowerCase(),
      password: password,
      password2: password,
      ungating: true,
      categoryOrBrand: categoryOrBrand,
      token: token.token,
      promoCode: promoCode,
      aaid: aaid,
    };
    this.props.submitNewPayment(newUser, this.props.history);
  };

  changeCategoryOrBrand = (categoryOrBrand) => {
    this.setState({
      anchorEl: null,
      categoryOrBrand,
    });
  };

  render() {
    const {
      errors,
      stripe,
      categoryOrBrand,
      anchorEl,
      email,
      ungatings,
      firstName,
      lastName,
      password,
      isFlipped,
    } = this.state;
    const { classes, auth } = this.props;
    const { pending } = auth;

    let userContent = (
      <>
        <TextFieldGroup
          placeholder="Email"
          name="email"
          type="email"
          value={email}
          onChange={this.onChange}
          error={errors.email}
        />
        <Grid
          container
          style={{
            width: "calc(100% + 24px)",
            margin: "-12px",
            paddingTop: "12px",
          }}
        >
          <Grid item sm={12} md={6} style={{ padding: "0 12px" }}>
            <TextFieldGroup
              placeholder="First name"
              name="firstName"
              value={firstName}
              onChange={this.onChange}
              error={errors.name}
            />
          </Grid>
          <Grid item sm={12} md={6} style={{ padding: "0 12px" }}>
            <TextFieldGroup
              placeholder="Last name"
              name="lastName"
              value={lastName}
              onChange={this.onChange}
              error={errors.name}
            />
          </Grid>
        </Grid>
        <div className={classes.linkContainer}>
          <a href={"/login"} className={classes.link}>
            Already have an Atlas account?
          </a>
        </div>
        <div className="wp-button-container">
          <button
            type="button"
            onClick={this.onSubmit}
            className="wp-style-button primary"
          >
            Continue
          </button>
        </div>
      </>
    );

    let paymentContent = (
      <>
        Create Password
        <TextFieldGroup
          placeholder="Password"
          name="password"
          type="password"
          value={password}
          onChange={this.onChange}
          error={errors.password}
        />
        {
          // <TextFieldGroup
          //   placeholder="Promo Code"
          //   name="promoCode"
          //   type="promoCode"
          //   success={this.state.goodPromo}
          //   value={this.state.promoCode}
          //   onChange={this.onChange}
          //   error={this.state.promoError}
          // />
        }
        Card Details
        <div>
          <StripeProvider stripe={stripe}>
            <Elements
              fonts={[
                {
                  cssSrc:
                    "https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700",
                },
              ]}
            >
              <CardForm
                classes={classes}
                cardholder={`${firstName} ${lastName}`}
                email={email}
                errors={errors}
                pending={pending}
                categoryOrBrandBenefits={categoryOrBrand.benefits}
                submitNewPayment={this.submitNewPayment}
              />
            </Elements>
          </StripeProvider>
        </div>
      </>
    );

    return (
      <div className="container">
        <div className="spinner" hidden={!pending}></div>
        <div className={classes.root}>
          <form name="checkout">
            <MuiThemeProvider theme={theme}>
              <Grid container spacing={1}>
                <Grid item sm={12} md={6}>
                  <ReactCardFlip
                    isFlipped={isFlipped}
                    flipDirection="horizontal"
                    infinite={true}
                  >
                    <div key="front">
                      <Paper className={classes.paper}>
                        <Typography variant="h6" className={classes.headline}>
                          <div className={classes.cardHeader}>
                            <div className={classes.cardHeaderTitle}>
                              <i className="fas fa-user" />
                              <span className={classes.cardHeaderTitleText}>
                                Create Account
                              </span>
                            </div>
                          </div>
                        </Typography>
                        <br />
                        {userContent}
                      </Paper>
                    </div>
                    <div key="back">
                      <Paper className={classes.paper}>
                        <Typography variant="h6" className={classes.headline}>
                          <div className={classes.cardHeader}>
                            <div className={classes.cardHeaderTitle}>
                              <i className="fas fa-user" />
                              <span className={classes.cardHeaderTitleText}>
                                Payment Information
                              </span>
                            </div>
                          </div>
                        </Typography>
                        {paymentContent}
                      </Paper>
                    </div>
                  </ReactCardFlip>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" className={classes.headline}>
                      <div className={classes.cardHeader}>
                        <div>
                          <i className="fas fa-shopping-cart" />
                          <span className={classes.cardHeaderTitleText}>
                            Order Summary
                          </span>
                        </div>
                        <button
                          type="button"
                          className="wp-style-button primary-outline"
                          onClick={(event) => {
                            this.setState({ anchorEl: event.currentTarget });
                          }}
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          disabled={pending}
                        >
                          Choose Here
                        </button>
                      </div>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => {
                          this.setState({ anchorEl: null });
                        }}
                      >
                        {ungatings.map((ungating) => (
                          <MenuItem
                            key={ungating._id}
                            onClick={() => this.changeCategoryOrBrand(ungating)}
                          >
                            {ungating.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Typography>

                    <Typography
                      variant="h6"
                      className={classes.headline}
                      style={{ textAlign: "center", fontSize: "25px" }}
                    >
                      {categoryOrBrand && categoryOrBrand.name}
                    </Typography>
                    <div
                      id="benefits"
                      style={{ paddingLeft: "19%", fontSize: "18px" }}
                    >
                      <ul style={{ margin: 0 }} className="fa-ul">
                        {categoryOrBrand &&
                          categoryOrBrand.benefits &&
                          categoryOrBrand.benefits.split("\n").map((row, i) => (
                            <li key={i}>
                              <i
                                className="far fa-check-circle"
                                style={{ color: "#029cea" }}
                              ></i>{" "}
                              {row}
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        color: "#029cea",
                        fontFamily: "Raleway",
                        fontWeight: 1000,
                      }}
                    >
                      <span style={{ fontSize: "18px" }}>$</span>
                      <span style={{ fontSize: "50px" }}>
                        {categoryOrBrand && categoryOrBrand.cost}
                      </span>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </MuiThemeProvider>
          </form>
        </div>
      </div>
    );
  }
}

CheckoutUngating.propTypes = {
  createLead: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  ungating: state.ungating,
});

export default withRouter(
  connect(mapStateToProps, {
    createLead,
    submitNewPayment,
    getPublicUngatingTypes,
  })(withStyles(styles)(CheckoutUngating))
);
